<template>
  <div>
    <a-form
      :form="form"
      :labelCol="labelCol"
      :wrapperCol="wrapperCol">
      <a-form-item
        hidden
        label="orderId"
      >
        <a-input
          v-decorator="['orderId',{ initialValue:'0' }]"
          name="orderId"></a-input>
      </a-form-item>
      <a-form-item
        hidden
        label="orderType"
      >
        <a-input
          v-decorator="['orderType',{ initialValue:'0' }]"
          name="orderType"></a-input>
      </a-form-item>
      <a-row>
        <a-col :span="12">
          <a-form-item
            label="姓名"
            :label-col="{ span: 8 }"
            :wrapper-col="{ span: 16 }"
          >
            <a-input
              v-decorator="[
                'truename',
                {rules: [{ required: true, message: '请输入姓名' }]}
              ]"
              name="truename"
              :disabled="isedit"
              placeholder="请输入姓名"/>
          </a-form-item>
          <a-form-item
            label="身份证号"
            :label-col="{ span: 8 }"
            :wrapper-col="{ span: 16 }"
          >
            <a-input
              :disabled="isedit"
              v-decorator="[
                'idcard',
                {rules: [{ required: true, message: '请输入证件号' }]}
              ]"
              name="idcard"
              placeholder="请输入身份证号"/>
          </a-form-item>
          <a-form-item
            label="手机号"
            :label-col="{ span: 8 }"
            :wrapper-col="{ span: 16 }"
          >
            <a-input
              v-decorator="[
                'mobile',
                {rules: [{ required: true, message: '请输入手机号' }]}
              ]"
              name="mobile"
              placeholder="请输入手机号"/>
          </a-form-item>

        </a-col>
        <a-col :span="12">
          <a-form-item
            :hidden="hideThum"
            label="上传住院凭证"
            :label-col="{ span: 8 }"
            :wrapper-col="{ span: 16 }">
            <AvatarUpload
              v-decorator="[
                'certificate'
              ]"/>
          </a-form-item>
        </a-col>
      </a-row>
      <a-form-item label="B端渠道" prop="channelId">
        <a-select
          v-decorator="[
            'channelId',
            {rules: [{ required: true, message: '请选择B端渠道' }]}
          ]"
          name="channelId"
          @change="channelProduct"
        >
          <a-select-option v-for="item in channelData" :key="item.channelId" :value="item.channelId">
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-item>

      <a-form-item label="B端产品" prop="channelProductId">
        <a-select
          v-decorator="[
            'channelProductId',
            {rules: [{ required: true, message: '请选择B端产品' }]}
          ]"
          name="channelProductId"
        >
          <a-select-option v-for="item in productdata" :key="item.channelProductId" :value="item.channelProductId">
            {{ item.name }}-{{ item.productName }}-{{ item.productChildName }}
          </a-select-option>
        </a-select>
      </a-form-item>

      <a-form-item
        :required="false"
        label="套餐类型"
        :hidden="hidesetmeal"
      >
        <a-select
          v-decorator="[
            'mealId'
          ]"
          name="mealId"
        >
          <a-select-option v-for="item in setmealdata" :key="item.id" :value="item.id">
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-item>

      <a-form-item label="地区" prop="regionId">
        <tree-select
          v-decorator="[
            'regionId',
            {rules: [{ required: true, message: '请选择地区' }]}
          ]"
          name="regionId"
          dropdownClassName="dropdownSelect"
          :replaceFields="RegreplaceFields"
          :tree-data="RegtreeData"
          allow-clear
          placeholder="请选择"
          @change="changeRegionhander"
        />
      </a-form-item>

      <a-form-item
        label="意向医院"
      >
        <a-select
          v-decorator="[
            'nosoId',
            {rules: [{ required: true, message: '请选择意向医院' }]}
          ]"
          name="nosoId"
          @change="nosohander"
        >
          <a-select-option v-for="item in nosodata" :key="item.id" :value="item.id">
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item
        label="医院地址"
      >
        <a-select
          v-decorator="[
            'addressId'
          ]"
          name="addressId"
        >
          <a-select-option v-for="item in nosoAddressdata" :key="item.addressId" :value="item.addressId">
            {{ item.address }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item
        label="意向科室"
        :hidden="hideDep"
      >
        <a-select
          v-decorator="[
            'nosoDepId'
          ]"
          name="nosoDepId"
          @change="nosoDephander"
        >
          <a-select-option v-for="item in nosodepdata" :key="item.nosoDepId" :value="item.nosoDepId">
            {{ item.name }}- {{ item.depName }} - {{ item.sectionName }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item
        label="意向医生"
        :hidden="hideDoctor"
      >
        <a-select
          v-decorator="[
            'doctorId'
          ]"
          name="doctorId"
        >
          <a-select-option v-for="item in doctordata" :key="item.doctorId" :value="item.doctorId">
            {{ item.trueName }}- {{ item.position }} - {{ item.lecturerName }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item
        label="意向时间"
      >
        <a-date-picker
          show-time
          format="YYYY-MM-DD HH:mm"
          v-decorator="[
            'subscribeDate'
          ]"
          name="subscribeDate"
          placeholder="意向时间"/>
      </a-form-item>
      <a-form-item
        :maxLength="500"
        label="客户信息备注"
      >
        <a-textarea
          :rows="4"
          v-decorator="[
            'remarks'
          ]"
          name="remarks"
          placeholder="请输入客户备注"/>
      </a-form-item>
    </a-form>
  </div>
</template>

<script>
import { cityList as RegAllList } from '@/api/region'
import { allList as nosodochiumList, addressList } from '@/api/nosodochium'
import { allList as setmealList } from '@/api/setmeal'
import { allList as channelList } from '@/api/channel'
import { allList as channelproductlList } from '@/api/channelproduct'
import { allList as nosodochiumdeplList } from '@/api/nosodochiumdep'
import { allList as doctorList } from '@/api/doctor'
import { TreeSelect } from 'ant-design-vue'
import AvatarUpload from '@/components/upload'

export default {
  components: {
    TreeSelect,
    AvatarUpload
  },
  data () {
    return {
      setmealdata: [],
      RegtreeData: [],
      nosodata: [],
      doctordata: [],
      channelData: [],
      productdata: [],
      nosodepdata: [],
      nosoAddressdata: [],
      RegreplaceFields: { children: 'children', title: 'name', key: 'regionId', value: 'regionId' },
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      form: this.$form.createForm(this),
      hideDep: false,
      hideDoctor: false,
      hideThum: false,
      hidesetmeal: false,
      isedit: false
    }
  },
  mounted () {
    this.getRegAllList()
    this.getChannelList()
    this.getSetmealList()
  },
  methods: {
    getRegAllList () {
      RegAllList().then(res => {
        this.RegtreeData = res.result
      })
    },
    getChannelList () {
      channelList().then(res => {
        this.channelData = res.result
      })
    },
    getNosodochiumList (regionId) {
      nosodochiumList({ regionIds: regionId }).then(res => {
        this.nosodata = res.result
        this.$forceUpdate()
      })
    },
    getNosodochiumdeplList (nosoId) {
      nosodochiumdeplList({ nosoId: nosoId }).then(res => {
        this.nosodepdata = res.result
        this.$forceUpdate()
      })
    },
    getSetmealList () {
      setmealList().then(res => {
        this.setmealdata = res.result
      })
    },
    getAddresslList (nosoId) {
      addressList({ nosoId: nosoId }).then(res => {
        this.nosoAddressdata = res.result.data
        this.$forceUpdate()
      })
    },

    getDoctorList (value) {
      doctorList({ nosoDepId: value }).then(res => {
        this.doctordata = res.result
        this.$forceUpdate()
      })
    },

    getProductlList (channelId) {
      const orderType = this.form.getFieldValue('orderType')
      channelproductlList({ channelId: channelId, orderType: orderType }).then(res => {
        this.productdata = res.result
        this.$forceUpdate()
      })
    },
    channelProduct (value) {
      this.form.setFieldsValue({
        channelProductId: undefined
      })
      this.getProductlList(value)
    },

    nosohander (nosoId) {
      this.form.setFieldsValue({
        nosoDepId: undefined,
        addressId: undefined,
        doctorId: undefined
      })
      this.getNosodochiumdeplList(nosoId)
      this.getAddresslList(nosoId)
    },
    changeRegionhander (value, label, extra) {
      this.form.setFieldsValue({
        nosoId: undefined,
        nosoDepId: undefined,
        addressId: undefined,
        doctorId: undefined
      })
      this.getNosodochiumList(value)
    },
    nosoDephander (value) {
      this.form.setFieldsValue({
        doctorId: undefined
      })
      this.getDoctorList(value)
    },
    ChangeOrderType (event) {
      const value = event.target.value
      this.ChangeOrderValue(value)
    },
    ChangeOrderValue (value) {
      if (value === 1) {
        this.form.setFieldsValue({
          channelId: undefined,
          channelProductId: undefined
        })
        this.hideDep = true
        this.hideDoctor = true
        this.hidesetmeal = false
      } else {
        this.hideDep = false
        this.hideDoctor = false
        this.hidesetmeal = true
      }
      if (value === 3) {
        this.hideThum = false
      } else {
        this.hideThum = true
      }
    },
    ChangeHideValue (value) {
      if (value === 1) {
        this.hideDep = true
        this.hideDoctor = true
        this.hidesetmeal = false
      } else {
        this.hideDep = false
        this.hideDoctor = false
        this.hidesetmeal = true
      }
      if (value === 3) {
        this.hideThum = false
      } else {
        this.hideThum = true
      }
    },
    setIsEdit (val) {
      this.isedit = val
    }
  }
}
</script>
