import request from '@/utils/request'

const api = {
  user: '/admincp/nosodochium/index',
  usercreate: '/admincp/nosodochium/create',
  userupdate: '/admincp/nosodochium/update',
  userinfo: '/admincp/nosodochium/info',
  userDel: '/admincp/nosodochium/delete',
  userDelete: '/admincp/nosodochium/moredel',
  clearstock: '/admincp/nosodochium/clearstock',
  clearstockone: '/admincp/nosodochium/clear/stockone',
  allList: '/admincp/nosodochium/allList',
  addressList: '/admincp/nosodochium/address/index',
  nosodochiumImp: '/admincp/nosodochium/imp',
  Download: '/admincp/nosodochium/download',
  excelShow: '/admincp/nosodochium/excelShow',
  importSave: '/admincp/nosodochium/import',
  userStatus: '/admincp/nosodochium/morestatus'

}

export function FetchList (parameter) {
  if (parameter === undefined) {
    parameter = {}
  }
  return request({
    url: api.user,
    method: 'post',
    data: parameter
  })
}

export function create (parameter) {
  if (parameter === undefined) {
    parameter = {}
  }
  return request({
    url: api.usercreate,
    method: 'post',
    data: parameter
  })
}

export function update (parameter) {
  if (parameter === undefined) {
    parameter = {}
  }
  return request({
    url: api.userupdate,
    method: 'post',
    data: parameter
  })
}

export function del (parameter) {
  if (parameter === undefined) {
    parameter = {}
  }
  return request({
    url: api.userDel,
    method: 'post',
    data: parameter
  })
}

export function moreDel (parameter) {
  if (parameter === undefined) {
    parameter = {}
  }
  return request({
    url: api.userDelete,
    method: 'post',
    data: parameter
  })
}
export function moreStatus (parameter) {
  if (parameter === undefined) {
    parameter = {}
  }
  return request({
    url: api.userStatus,
    method: 'post',
    data: parameter
  })
}
export function clearstock (parameter) {
  if (parameter === undefined) {
    parameter = {}
  }
  return request({
    url: api.clearstock,
    method: 'post',
    data: parameter
  })
}

export function clearstockone (parameter) {
  if (parameter === undefined) {
    parameter = {}
  }
  return request({
    url: api.clearstockone,
    method: 'post',
    data: parameter
  })
}

export function getById (parameter) {
  if (parameter === undefined) {
    parameter = {}
  }
  return request({
    url: api.userinfo,
    method: 'post',
    data: parameter
  })
}

export function allList (parameter) {
  if (parameter === undefined) {
    parameter = {}
  }
  return request({
    url: api.allList,
    method: 'post',
    data: parameter
  })
}

export function addressList (parameter) {
  if (parameter === undefined) {
    parameter = {}
  }
  return request({
    url: api.addressList,
    method: 'post',
    data: parameter
  })
}

export function download (parameter) {
  if (parameter === undefined) {
    parameter = {}
  }
  return request({
    url: '/pub/nosodochium/down',
    method: 'post',
    responseType: 'blob',
    data: parameter
  })
}

export function nosodochiumImp (parameter) {
  if (parameter === undefined) {
    parameter = {}
  }
  return request({
    url: api.nosodochiumImp,
    method: 'post',
    data: parameter
  })
}
export function excelShow (parameter) {
  if (parameter === undefined) {
    parameter = {}
  }
  return request({
    url: api.excelShow,
    method: 'post',
    data: parameter
  })
}

export function DownloadExcel (parameter) {
  if (parameter === undefined) {
    parameter = {}
  }
  return request({
    url: api.Download,
    method: 'post',
    responseType: 'blob',
    data: parameter
  })
}

 export function importSave (parameter) {
  if (parameter === undefined) {
    parameter = {}
  }
  return request({
    url: api.importSave + '?t=' + Date.parse(new Date()),
    method: 'post',
    data: parameter
  })
}
