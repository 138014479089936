import request from '@/utils/request'

const api = {
  user: '/admincp/doctor/index',
  usercreate: '/admincp/doctor/create',
  userupdate: '/admincp/doctor/update',
  userinfo: '/admincp/doctor/info',
  userDel: '/admincp/doctor/delete',
  userDelete: '/admincp/doctor/moredel',
  allList: '/admincp/doctor/allList',
  uploaderImp: '/admincp/doctor/uploaderImp',
  download: '/admincp/doctor/download'
}

export function FetchList (parameter) {
  if (parameter === undefined) {
    parameter = {}
  }
  return request({
    url: api.user,
    method: 'post',
    data: parameter
  })
}

export function create (parameter) {
  if (parameter === undefined) {
    parameter = {}
  }
  return request({
    url: api.usercreate,
    method: 'post',
    data: parameter
  })
}

export function update (parameter) {
  if (parameter === undefined) {
    parameter = {}
  }
  return request({
    url: api.userupdate,
    method: 'post',
    data: parameter
  })
}

export function del (parameter) {
  if (parameter === undefined) {
    parameter = {}
  }
  return request({
    url: api.userDel,
    method: 'post',
    data: parameter
  })
}

export function moreDel (parameter) {
  if (parameter === undefined) {
    parameter = {}
  }
  return request({
    url: api.userDelete,
    method: 'post',
    data: parameter
  })
}

export function getById (parameter) {
  if (parameter === undefined) {
    parameter = {}
  }
  return request({
    url: api.userinfo,
    method: 'post',
    data: parameter
  })
}

export function allList (parameter) {
  if (parameter === undefined) {
    parameter = {}
  }
  return request({
    url: api.allList,
    method: 'post',
    data: parameter
  })
}

export function download (parameter) {
  if (parameter === undefined) {
    parameter = {}
  }
  return request({
    url: api.download,
    method: 'post',
    responseType: 'blob',
    data: parameter
  })
}

export function uploaderImp (parameter) {
  if (parameter === undefined) {
    parameter = {}
  }
  return request({
    url: api.uploaderImp,
    method: 'post',
    data: parameter
  })
}
