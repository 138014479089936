<template>
  <div>
    <a-input hidden v-model="aid"/>
    <a-upload
      :disabled="isDisabled"
      name="file"
      list-type="picture-card"
      class="avatar-uploader"
      :show-upload-list="false"
      :action="uploadUrl"
      :headers="headers"
      :before-upload="beforeUpload"
      @change="handleChange"
    >
      <img v-if="imageUrl" :src="imageUrl" alt="file" width="128" height="128"/>
      <div v-else>
        <a-icon :type="loading ? 'loading' : 'plus'"/>
        <div class="ant-upload-text">
          上传
        </div>
      </div>
    </a-upload>
  </div>
</template>

<script>
  import storage from 'store'
  import { ACCESS_TOKEN } from '@/store/mutation-types'
  import store from '@/store'

  export default {
    props: {
      value: {
        type: [String, Number]
      },
      isDisabled: {
        type: Boolean,
        default: false
      }
    },
    data () {
      return {
        loading: false,
        imageUrl: '',
        headers: null,
        uploadUrl: '',
        uploadValue: '',
        aid: ''
      }
    },
    watch: {
      value (val) {
        this.aid = val
        if (this.aid !== '' && this.aid !== '0' && this.aid !== undefined) {
          this.imageUrl = '/web/attr/show?attr_id=' + this.aid
        }
      }
    },
    mounted () {
      this.uploadUrl = '/admincp/upload/index'
      const token = storage.get(ACCESS_TOKEN)
      if (!token) {
        store.dispatch('Logout').then(() => {
          setTimeout(() => {
            window.location.reload()
          }, 1500)
        })
      }
      this.headers = { 'Access-Token': token, 'contextType': 'multipart/form-data; charset=utf-8' }
    },
    methods: {
      handleChange (info) {
        if (info.file.status === 'uploading') {
          this.loading = true
          return
        }
        if (info.file.status === 'done') {
          if (info.file.response.result) {
            this.imageUrl = info.file.response.result.url
            this.$emit('change', info.file.response.result.aid)
          }
          this.loading = false
        }
      },
      beforeUpload (file) {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
        if (!isJpgOrPng) {
          this.$message.error('请选中上传jpeg或者png图片')
        }
        const isLt2M = file.size / 1024 / 1024 < 2
        if (!isLt2M) {
          this.$message.error('图片大小不能超过2M!')
        }
        return isJpgOrPng && isLt2M
      }
    }
  }
</script>
<style>
  .avatar-uploader > .ant-upload {
    width: 128px;
    height: 128px;
  }

  .ant-upload-select-picture-card i {
    font-size: 32px;
    color: #999;
  }

  .ant-upload-select-picture-card .ant-upload-text {
    margin-top: 8px;
    color: #666;
  }
</style>
