var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-form',{attrs:{"form":_vm.form,"labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_c('a-form-item',{attrs:{"hidden":"","label":"orderId"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['orderId',{ initialValue:'0' }]),expression:"['orderId',{ initialValue:'0' }]"}],attrs:{"name":"orderId"}})],1),_c('a-form-item',{attrs:{"hidden":"","label":"orderType"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['orderType',{ initialValue:'0' }]),expression:"['orderType',{ initialValue:'0' }]"}],attrs:{"name":"orderType"}})],1),_c('a-row',[_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"姓名","label-col":{ span: 8 },"wrapper-col":{ span: 16 }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'truename',
              {rules: [{ required: true, message: '请输入姓名' }]}
            ]),expression:"[\n              'truename',\n              {rules: [{ required: true, message: '请输入姓名' }]}\n            ]"}],attrs:{"name":"truename","disabled":_vm.isedit,"placeholder":"请输入姓名"}})],1),_c('a-form-item',{attrs:{"label":"身份证号","label-col":{ span: 8 },"wrapper-col":{ span: 16 }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'idcard',
              {rules: [{ required: true, message: '请输入证件号' }]}
            ]),expression:"[\n              'idcard',\n              {rules: [{ required: true, message: '请输入证件号' }]}\n            ]"}],attrs:{"disabled":_vm.isedit,"name":"idcard","placeholder":"请输入身份证号"}})],1),_c('a-form-item',{attrs:{"label":"手机号","label-col":{ span: 8 },"wrapper-col":{ span: 16 }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'mobile',
              {rules: [{ required: true, message: '请输入手机号' }]}
            ]),expression:"[\n              'mobile',\n              {rules: [{ required: true, message: '请输入手机号' }]}\n            ]"}],attrs:{"name":"mobile","placeholder":"请输入手机号"}})],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"hidden":_vm.hideThum,"label":"上传住院凭证","label-col":{ span: 8 },"wrapper-col":{ span: 16 }}},[_c('AvatarUpload',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'certificate'
            ]),expression:"[\n              'certificate'\n            ]"}]})],1)],1)],1),_c('a-form-item',{attrs:{"label":"B端渠道","prop":"channelId"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'channelId',
          {rules: [{ required: true, message: '请选择B端渠道' }]}
        ]),expression:"[\n          'channelId',\n          {rules: [{ required: true, message: '请选择B端渠道' }]}\n        ]"}],attrs:{"name":"channelId"},on:{"change":_vm.channelProduct}},_vm._l((_vm.channelData),function(item){return _c('a-select-option',{key:item.channelId,attrs:{"value":item.channelId}},[_vm._v(" "+_vm._s(item.name)+" ")])}),1)],1),_c('a-form-item',{attrs:{"label":"B端产品","prop":"channelProductId"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'channelProductId',
          {rules: [{ required: true, message: '请选择B端产品' }]}
        ]),expression:"[\n          'channelProductId',\n          {rules: [{ required: true, message: '请选择B端产品' }]}\n        ]"}],attrs:{"name":"channelProductId"}},_vm._l((_vm.productdata),function(item){return _c('a-select-option',{key:item.channelProductId,attrs:{"value":item.channelProductId}},[_vm._v(" "+_vm._s(item.name)+"-"+_vm._s(item.productName)+"-"+_vm._s(item.productChildName)+" ")])}),1)],1),_c('a-form-item',{attrs:{"required":false,"label":"套餐类型","hidden":_vm.hidesetmeal}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'mealId'
        ]),expression:"[\n          'mealId'\n        ]"}],attrs:{"name":"mealId"}},_vm._l((_vm.setmealdata),function(item){return _c('a-select-option',{key:item.id,attrs:{"value":item.id}},[_vm._v(" "+_vm._s(item.name)+" ")])}),1)],1),_c('a-form-item',{attrs:{"label":"地区","prop":"regionId"}},[_c('tree-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'regionId',
          {rules: [{ required: true, message: '请选择地区' }]}
        ]),expression:"[\n          'regionId',\n          {rules: [{ required: true, message: '请选择地区' }]}\n        ]"}],attrs:{"name":"regionId","dropdownClassName":"dropdownSelect","replaceFields":_vm.RegreplaceFields,"tree-data":_vm.RegtreeData,"allow-clear":"","placeholder":"请选择"},on:{"change":_vm.changeRegionhander}})],1),_c('a-form-item',{attrs:{"label":"意向医院"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'nosoId',
          {rules: [{ required: true, message: '请选择意向医院' }]}
        ]),expression:"[\n          'nosoId',\n          {rules: [{ required: true, message: '请选择意向医院' }]}\n        ]"}],attrs:{"name":"nosoId"},on:{"change":_vm.nosohander}},_vm._l((_vm.nosodata),function(item){return _c('a-select-option',{key:item.id,attrs:{"value":item.id}},[_vm._v(" "+_vm._s(item.name)+" ")])}),1)],1),_c('a-form-item',{attrs:{"label":"医院地址"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'addressId'
        ]),expression:"[\n          'addressId'\n        ]"}],attrs:{"name":"addressId"}},_vm._l((_vm.nosoAddressdata),function(item){return _c('a-select-option',{key:item.addressId,attrs:{"value":item.addressId}},[_vm._v(" "+_vm._s(item.address)+" ")])}),1)],1),_c('a-form-item',{attrs:{"label":"意向科室","hidden":_vm.hideDep}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'nosoDepId'
        ]),expression:"[\n          'nosoDepId'\n        ]"}],attrs:{"name":"nosoDepId"},on:{"change":_vm.nosoDephander}},_vm._l((_vm.nosodepdata),function(item){return _c('a-select-option',{key:item.nosoDepId,attrs:{"value":item.nosoDepId}},[_vm._v(" "+_vm._s(item.name)+"- "+_vm._s(item.depName)+" - "+_vm._s(item.sectionName)+" ")])}),1)],1),_c('a-form-item',{attrs:{"label":"意向医生","hidden":_vm.hideDoctor}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'doctorId'
        ]),expression:"[\n          'doctorId'\n        ]"}],attrs:{"name":"doctorId"}},_vm._l((_vm.doctordata),function(item){return _c('a-select-option',{key:item.doctorId,attrs:{"value":item.doctorId}},[_vm._v(" "+_vm._s(item.trueName)+"- "+_vm._s(item.position)+" - "+_vm._s(item.lecturerName)+" ")])}),1)],1),_c('a-form-item',{attrs:{"label":"意向时间"}},[_c('a-date-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'subscribeDate'
        ]),expression:"[\n          'subscribeDate'\n        ]"}],attrs:{"show-time":"","format":"YYYY-MM-DD HH:mm","name":"subscribeDate","placeholder":"意向时间"}})],1),_c('a-form-item',{attrs:{"maxLength":500,"label":"客户信息备注"}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'remarks'
        ]),expression:"[\n          'remarks'\n        ]"}],attrs:{"rows":4,"name":"remarks","placeholder":"请输入客户备注"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }