import request from '@/utils/request'

const api = {
  user: '/admincp/nosodochium/dep/index',
  usercreate: '/admincp/nosodochium/dep/create',
  userupdate: '/admincp/nosodochium/dep/update',
  userinfo: '/admincp/nosodochium/dep/info',
  userDel: '/admincp/nosodochium/dep/delete',
  userDelete: '/admincp/nosodochium/dep/moredel',
  allList: '/admincp/nosodochium/dep/allList',
  nosodochiumDepImp: '/admincp/nosodochium/dep/imp',
  excelShow: '/admincp/nosodochium/dep/excelShow',
  importSave: '/admincp/nosodochium/dep/import'
}

export function FetchList (parameter) {
  if (parameter === undefined) {
    parameter = {}
  }
  return request({
    url: api.user,
    method: 'post',
    data: parameter
  })
}

export function create (parameter) {
  if (parameter === undefined) {
    parameter = {}
  }
  return request({
    url: api.usercreate,
    method: 'post',
    data: parameter
  })
}

export function update (parameter) {
  if (parameter === undefined) {
    parameter = {}
  }
  return request({
    url: api.userupdate,
    method: 'post',
    data: parameter
  })
}

export function del (parameter) {
  if (parameter === undefined) {
    parameter = {}
  }
  return request({
    url: api.userDel,
    method: 'post',
    data: parameter
  })
}

export function moreDel (parameter) {
  if (parameter === undefined) {
    parameter = {}
  }
  return request({
    url: api.userDelete,
    method: 'post',
    data: parameter
  })
}

export function getById (parameter) {
  if (parameter === undefined) {
    parameter = {}
  }
  return request({
    url: api.userinfo,
    method: 'post',
    data: parameter
  })
}

export function allList (parameter) {
  if (parameter === undefined) {
    parameter = {}
  }
  return request({
    url: api.allList,
    method: 'post',
    data: parameter
  })
}
export function DepartmentDownload (parameter) {
  if (parameter === undefined) {
    parameter = {}
  }
  return request({
    url: '/pub/nosodochiumdep/down',
    method: 'post',
    responseType: 'blob',
    data: parameter
  })
}

export function nosodochiumDepImp (parameter) {
  if (parameter === undefined) {
    parameter = {}
  }
  return request({
    url: api.nosodochiumDepImp,
    method: 'post',
    data: parameter
  })
}
export function excelShow (parameter) {
  if (parameter === undefined) {
    parameter = {}
  }
  return request({
    url: api.excelShow,
    method: 'post',
    data: parameter
  })
}
export function importSave (parameter) {
  if (parameter === undefined) {
    parameter = {}
  }
  return request({
    url: api.importSave + '?t=' + Date.parse(new Date()),
    method: 'post',
    data: parameter
  })
}
